var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"persistent":"","max-width":"450px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('v-card',{attrs:{"disabled":_vm.loading,"loading":_vm.loading}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t("hr.travel_request.travel_approval"))+" "),_c('v-spacer'),_c('v-btn',{attrs:{"depressed":"","icon":""},on:{"click":_vm.onClickCancel}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),(_vm.data)?_c('v-card-text',{staticClass:"pt-3"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{domProps:{"innerHTML":_vm._s(
                _vm.$t('hr.travel_request.message', [
                  `${_vm.data.travel.employee.firstName} ${_vm.data.travel.employee.lastName}`,
                  _vm.$helpers.formattedDate(_vm.data.travel.beginDate),
                  _vm.$helpers.formattedDate(_vm.data.travel.endDate)
                ])
              )}})]),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('global.note'),"rules":"max:500"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-textarea',{attrs:{"rows":"1","name":"note","counter":"500","label":_vm.$t('global.note'),"error-messages":errors},model:{value:(_vm.travelApprovalDTO.note),callback:function ($$v) {_vm.$set(_vm.travelApprovalDTO, "note", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"travelApprovalDTO.note"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-radio-group',{attrs:{"row":"","mandatory":""},model:{value:(_vm.travelApprovalDTO.approved),callback:function ($$v) {_vm.$set(_vm.travelApprovalDTO, "approved", $$v)},expression:"travelApprovalDTO.approved"}},[_c('v-radio',{attrs:{"color":"primary","value":false,"label":_vm.$t('leave.request.denied')}}),_c('v-radio',{attrs:{"color":"primary","value":true,"label":_vm.$t('leave.request.approved')}})],1)],1)],1)],1):_vm._e(),_c('v-card-actions',{staticClass:"dialog-footer"},[_c('v-spacer'),_c('v-btn',{attrs:{"depressed":""},on:{"click":function($event){$event.stopPropagation();return handleSubmit(_vm.onClickSave)}}},[_vm._v(_vm._s(_vm.$t("buttons.save")))])],1)],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }